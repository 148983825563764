import React from "react"
import { graphql } from "gatsby"
import { useIntl, Link } from "gatsby-plugin-intl"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import KfgCategories from "../../components/kfgCategories"
import KfgThumbnail from "../../components/kfgThumbnail"

const Illustrations = ({ data }) => {
  const intl = useIntl()
  return (
    <Layout>
      <SEO
        title={intl.formatMessage({ id: "category.kfg" })}
        image="https://flat-svg-designs.net/images/kfg-ogp.png"
        description={intl.formatMessage({ id: "kfg.description" })}
      />
      <section className="w-full md:w-3/4 flex flex-col px-3">
        <h3 className="font-semibold mb-3 text-xl leading-tight sm:leading-normal">
          {intl.formatMessage({ id: "kfg.title" })}
        </h3>
        <div className="main-images mb-3">
          <KfgCategories />
        </div>
        <div className="main-images mb-8">
          <div className="images grid grid-cols-2 gap-2 md:grid-cols-3 md:gap-3">
            <KfgThumbnail kfg={data.kfg} />
          </div>
        </div>
      </section>
    </Layout>
  )
}
export default Illustrations

export const query = graphql`
  query {
    kfg: allSvgItemsJson(
      sort: { fields: id, order: DESC }
      filter: { cat: { eq: "kung-fu-geek" } }
    ) {
      edges {
        node {
          id
          cat
          name
          tag
        }
      }
    }
  }
`
